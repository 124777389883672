import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { Footer, HomePage, PageNotFound, VideoCard } from './pages/HomePage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />}></Route>
        <Route path="/movies/:title" element={<VideoCard />}></Route>
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
