import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Logo from "../logo.png";
import { getRandomEmoji } from "../components/Emoji";

export const HomePage = () => {
  const [types, setTypes] = useState([]);
  useEffect(() => {
    let getTypes = async () => {
      try {
        let localType = JSON.parse(localStorage.getItem("types"));
        if (localType !== null) {
          setTypes(localType);
        } else {
          let dataUrl = `https://api-filmseeker.jotonsutradhar.com/api/types`;
          let response = await axios.get(dataUrl, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          setTypes(response.data);
          localStorage.setItem("types", JSON.stringify(response.data));
        }
      } catch (error) {
        console.log(error);
      }
    };

    getTypes();
  }, []);
  return (
    <React.Fragment>
      <section className="bg-gray-900 text-white">
        <div className="mx-auto max-w-screen-xl px-4 py-3 lg:flex lg:items-center">
          <div className="mx-auto max-w-3xl text-center">
            <nav className="bg-gray border-gray-800 bg-gray-800 my-2 rounded-lg">
              <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto py-3 px-3">
                <Link
                  to={"/"}
                  className="flex items-center space-x-3 rtl:space-x-reverse"
                >
                  <span className="self-center text-3xl font-semibold whitespace-nowrap dark:text-white">
                    🍿
                  </span>
                </Link>
                <p className="text-2xl font-bold"> FilmSeeker</p>
                <Link
                  target="_blank"
                  to={"https://github.com/jotonsd/"}
                  className="flex items-center space-x-3 rtl:space-x-reverse"
                >
                  <span className="self-center text-3xl font-semibold whitespace-nowrap dark:text-white">
                    <img
                      src="https://qph.cf2.quoracdn.net/main-qimg-729a22aba98d1235fdce4883accaf81e"
                      height={45} width={45}
                    />
                  </span>
                </Link>
              </div>
            </nav>
            <h1 className="bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl">
              Discover top-rated movies
            </h1>
            <p className="mx-auto mt-4 max-w-xl sm:text-xl/relaxed">
              What kind of movies you want to watch? {<GetEmoji />} Just
              Click!😎
            </p>
            <div className="p-6 flex flex-wrap justify-center">
              {types.length > 0 &&
                types.map((type, index) => {
                  return (
                    <Link
                      key={index++}
                      to={"/movies/" + type.name}
                      type="button"
                      className={buttonClass(type.type)}
                    >
                      {type.emoji} {capitalizeFirstLetter(type.name)}
                    </Link>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const VideoCard = () => {
  const navigate = useNavigate();
  const [position, setPosition] = useState(0);
  const [max, setMax] = useState(false);
  const [min, setMin] = useState(true);
  const [loader, setLoader] = useState(true);
  const [movies, setMovies] = useState([]);
  const [genres, setGenre] = useState([]);
  const { title } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoader(true);
    let getMovies = async () => {
      try {
        let dataUrl = `https://api-filmseeker.jotonsutradhar.com/api/movies/${title}`;
        let response = await axios.get(dataUrl, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.data.length === 0) {
          navigate("/not-found");
        }
        setMovies(response.data);
        setGenre(JSON.parse(response.data[position].genres));
        setLoader(false);

        if (1 === response.data.length) {
          setMax(true);
        }
      } catch (error) {
        console.log(error);
        setLoader(false);
      }
    };

    getMovies();
  }, [title]);

  function positionIncrease() {
    if (position < movies.length - 1) {
      window.scrollTo(0, 0);
      setPosition(position + 1);
      setGenre(JSON.parse(movies[position + 1].genres));
      setMin(false);
    }
    if (position === movies.length - 2) {
      setMax(true);
    }
  }

  function positionDecrease() {
    if (position >= 1) {
      window.scrollTo(0, 0);
      setPosition(position - 1);
      setMax(false);
      setGenre(JSON.parse(movies[position - 1].genres));
    }
    if (position === 1) {
      setMin(true);
    }
  }

  return (
    <React.Fragment>
      <section className="bg-gray-900 text-white">
        <div className="mx-auto max-w-screen-xl px-4 py-3 lg:flex lg:items-center">
          <div className="mx-auto max-w-3xl text-center">
            <nav className="bg-gray border-gray-800 bg-gray-800 my-2 rounded-lg">
              <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto py-2 px-3">
                <Link
                  to={"/"}
                  className="flex items-center space-x-3 rtl:space-x-reverse"
                >
                  <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
                    🍿 FilmSeeker
                  </span>
                </Link>
                <p className="hidden md:block">
                  Searching {<GetEmoji />} {capitalizeFirstLetter(title)}
                </p>
                <Link
                  to={"/"}
                  type="button"
                  className=" inline-block px-6 py-3.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                >
                  🖌 Edit Genre
                </Link>
              </div>
            </nav>
            {loader ? (
              <Spinner />
            ) : (
              <>
                <div className="aspect-video">
                  <iframe
                    title="myFrame"
                    className="w-full h-full rounded-t-lg"
                    src={
                      "https://www.youtube.com/embed/" +
                      movies[position].trailer
                    }
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
                <div className="p-3 bg-gray-800 text-left">
                  <h1 className="text-white-900 text-3xl font-bold mb-3">
                    {movies[position].title}
                  </h1>
                  <p>
                    {movies[position].year} . {movies[position].length} . ⭐{" "}
                    {movies[position].rating} / 10
                  </p>
                  <div className="">
                    <button
                      to={"#"}
                      type="button"
                      className="text-red-700 border border-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-bold rounded-lg text-sm px-2 py-0.5 text-center me-2 mb-3 dark:border-red-800 dark:text-red-500 dark:focus:ring-red-800"
                    >
                      🎥 {movies[position].industry}
                    </button>
                    {genres.length > 0 &&
                      genres.map((genre, index) => {
                        return (
                          <Link
                            key={index++}
                            to={`/movies/${genre}`}
                            className="text-red-700 border border-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-bold rounded-lg text-sm px-2 py-0.5 text-center me-2 mb-3 dark:border-red-800 dark:text-red-500 dark:focus:ring-red-800"
                          >
                            {genre}
                          </Link>
                        );
                      })}
                  </div>
                  <p className="text-white-700 mb-4">
                    {movies[position].description}
                  </p>
                  <div className="flex justify-between item-center">
                    <button
                      onClick={positionDecrease}
                      type="button"
                      className={
                        min
                          ? "inline-block px-6 py-3.5 bg-gray-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md focus:bg-gray-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-800 active:shadow-lg transition duration-150 ease-in-out"
                          : "inline-block px-6 py-3.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-orange-700 hover:shadow-lg focus:bg-orange-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-orange-800 active:shadow-lg transition duration-150 ease-in-out"
                      }
                    >
                      ◀️ Previous
                    </button>
                    <button
                      onClick={positionIncrease}
                      type="button"
                      className={
                        max
                          ? "inline-block px-6 py-3.5 bg-gray-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md focus:bg-gray-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-800 active:shadow-lg transition duration-150 ease-in-out"
                          : "inline-block px-6 py-3.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-orange-700 hover:shadow-lg focus:bg-orange-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-orange-800 active:shadow-lg transition duration-150 ease-in-out"
                      }
                    >
                      Next ▶️
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export const Footer = () => {
  return (
    <React.Fragment>
      <footer className="mx-auto max-w-3xl rounded-lg shadow my-4 bg-gray-800">
        <div className="w-full max-w-screen-xl p-4 flex items-center justify-center">
          <span className="text-sm text-gray-500 sm:text-center text-gray-400">
            Made with 🍿 by
            <a
              href="https://jotonsutradhar.com/"
              className="hover:underline"
              target="_blank"
            >
              <span className="text-red-500 font-bold"> Joton Sutradhar</span>
            </a>
          </span>
        </div>
      </footer>
    </React.Fragment>
  );
};

export const Spinner = () => {
  return (
    <React.Fragment>
      <div className="text-center aspect-video">
        <h4 className="mt-12">
          We're fetching the best movies to watch for the genere. Please wait a
          moment!{<GetEmoji />}
        </h4>
        <div className="flex justify-center mt-12 spinner">
          <div
            className="w-12 h-12 rounded-full animate-spin
                    border-8 border-solid border-red-500 border-t-transparent"
          ></div>
        </div>
      </div>
    </React.Fragment>
  );
};

export const PageNotFound = () => {
  return (
    <React.Fragment>
      <section className="bg-gray-900 text-white">
        <div className="mx-auto max-w-screen-xl px-4 py-3 lg:flex lg:items-center">
          <div className="mx-auto max-w-3xl text-center">
            <nav className="bg-gray border-gray-800 bg-gray-800 my-2 rounded-lg">
              <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto py-2 px-3">
                <Link
                  to={"/"}
                  className="flex items-center space-x-3 rtl:space-x-reverse"
                >
                  <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
                    🍿 FilmSeeker
                  </span>
                </Link>
                <Link
                  to={"/"}
                  type="button"
                  className=" inline-block px-6 py-3.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                >
                  🖌 Edit Genre
                </Link>
              </div>
            </nav>
            <div className="flex justify-center py-16">
              <img height={100} width={100} src={Logo} alt="" />
            </div>
            <h1 className="bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl">
              404 <br />
              Opps! No suggestion for now please try another!
            </h1>
            <div className="mt-8 flex flex-wrap justify-center gap-4">
              <Link
                className="block w-full rounded border border-red-600 bg-red-600 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-white focus:outline-none focus:ring active:text-opacity-75 sm:w-auto"
                to={"/"}
              >
                Back to home page
              </Link>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export const GetEmoji = () => {
  const [emoji, setEmoji] = useState("😀");
  const MINUTE_MS = 900;

  useEffect(() => {
    const interval = setInterval(() => {
      setEmoji(getRandomEmoji());
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);
  return (
    <React.Fragment>
      <span>{emoji}</span>
    </React.Fragment>
  );
};

const buttonClass = (type) => {
  const typeWiseClas = {
    purple:
      "text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg px-8 py-4 text-center me-2 mb-4",
    cyan: "text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg px-8 py-4 text-center me-2 mb-4",
    green:
      "text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 font-medium rounded-lg px-8 py-4 text-center me-2 mb-4",
    pink: "text-white bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-medium rounded-lg px-8 py-4 text-center me-2 mb-4",
    orange:
      "text-white bg-gradient-to-br from-pink-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium rounded-lg px-8 py-4 text-center me-2 mb-4",
    teal: "text-gray-900 bg-gradient-to-r from-teal-200 to-lime-200 hover:bg-gradient-to-l hover:from-teal-200 hover:to-lime-200 focus:ring-4 focus:outline-none focus:ring-lime-200 dark:focus:ring-teal-700 font-medium rounded-lg px-8 py-4 text-center me-2 mb-4",
    red: "text-gray-900 bg-gradient-to-r from-red-200 via-red-300 to-yellow-200 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400 font-medium rounded-lg px-8 py-4 text-center me-2 mb-4",
  };

  return typeWiseClas[type];
};
