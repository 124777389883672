export const getRandomEmoji = () => {
  const emojis = [
    '😀',
    '😃',
    '😄',
    '😁',
    '😆',
    '😅',
    '🤣',
    '😂',
    '🙂',
    '🙃',
    '😉',
    '😊',
    '😇',
    '🥰',
    '😍',
    '🤩',
    '😘',
    '😗',
    '☺',
    '😚',
    '😙',
    '🥲',
    '😋',
    '😛',
    '😜',
    '🤪',
    '😝',
    '🤑',
    '🤗',
    '🤭',
    '🤫',
    '🤔',
    '🤐',
    '🤨',
    '😐',
    '😑',
    '😶',
    '😶‍🌫️',
    '😏',
    '😒',
    '🙄',
    '😬',
    '😮‍💨',
    '🤥',
    '😌',
    '😔',
    '😪',
    '🤤',
    '😴',
    '😷',
    '🤒',
    '🤕',
    '🤢',
    '🤮',
    '🤧',
    '🥵',
    '🥶',
    '🥴',
    '😵',
    '😵‍💫',
    '🤯',
    '🤠',
    '🥳',
    '😎',
    '🤓',
    '🧐',
    '😕',
    '😟',
    '🙁',
    '☹',
    '😮',
    '😯',
    '😲',
    '😳',
    '🥺',
    '😦',
    '😧',
    '😨',
    '😰',
    '😥',
    '😢',
    '😭',
    '😱',
    '😖',
    '😣',
    '😞',
    '😓',
    '😩',
    '😫',
    '🥱',
    '😤',
    '😡',
    '😠',
    '🤬',
    '😈',
    '👿',
    '💀',
    '☠',
    '💩',
    '🤡',
    '👹',
    '👺',
    '👻',
    '👽',
    '👾',
    '🤖',
    '😺',
    '😸',
    '😹',
    '😻',
    '😼',
    '😽',
    '🙀',
    '😿',
    '😾',
    '🙈',
    '🙉',
    '🙊',
    '💋',
    '💌',
    '💘',
    '💝',
    '💖',
    '💗',
    '💓',
    '💞',
    '💕',
    '💟',
    '❣',
    '💔',
    '❤️‍🔥',
    '❤️‍🩹',
    '❤',
    '🧡',
    '💛',
    '💚',
    '💙',
    '💜',
    '🤎',
    '🖤',
    '🤍',
    '💯',
    '💢',
    '💥',
    '💫',
    '💦',
    '💨',
    '🕳',
    '💣',
    '💬',
    '👁️‍🗨️',
    '🗨',
    '🗯',
    '💭',
    '💤',
    '👋',
    '🤚',
    '🖐',
    '✋',
    '🖖',
    '👌',
    '🤏',
    '✌',
    '🤞',
    '🤟',
    '🤘',
    '🤙',
    '👈',
    '👉',
    '👆',
    '🖕',
    '👇',
    '☝',
    '👍',
    '👎',
    '✊',
    '👊',
    '🤛',
    '🤜',
    '👏',
    '🙌',
    '👐',
    '🤲',
    '🤝',
    '🙏',
    '✍',
    '💅',
    '🤳',
    '💪',
    '🦾',
    '🦿',
    '🦵',
    '🦶',
    '👂',
    '🦻',
    '👃',
    '🧠',
    '🦷',
    '🦴',
    '👀',
    '👁',
    '👅',
    '👄',
    '👶',
    '🧒',
    '👦',
    '👧',
    '🧑',
    '👱',
    '👨',
    '🧔',
    '🧔‍♂️',
    '🧔‍♀️',
    '👨‍🦰',
    '👨‍🦱',
    '👨‍🦳',
    '👨‍🦲',
    '👩',
    '👩‍🦰',
    '🧑‍🦰',
    '👩‍🦱',
    '🧑‍🦱',
    '👩‍🦳',
    '🧑‍🦳',
    '👩‍🦲',
    '🧑‍🦲',
    '👱‍♀️',
    '👱‍♂️',
    '🧓',
    '👴',
    '👵',
    '🙍',
    '🙍‍♂️',
    '🙍‍♀️',
    '🙎',
    '🙎‍♂️',
    '🙎‍♀️',
    '🙅',
    '🙅‍♂️',
    '🙅‍♀️',
    '🙆',
    '🙆‍♂️',
    '🙆‍♀️',
    '💁',
    '💁‍♂️',
    '💁‍♀️',
    '🙋',
    '🙋‍♂️',
    '🙋‍♀️',
    '🧏',
    '🧏‍♂️',
    '🧏‍♀️',
    '🙇',
    '🙇‍♂️',
    '🙇‍♀️',
    '🤦',
    '🤦‍♂️',
    '🤦‍♀️',
    '🤷',
    '🤷‍♂️',
    '🤷‍♀️',
    '🧑‍⚕️',
    '👨‍⚕️',
    '👩‍⚕️',
    '🧑‍🎓',
    '👨‍🎓',
    '👩‍🎓',
    '🧑‍🏫',
    '👨‍🏫',
    '👩‍🏫',
    '🧑‍⚖️',
    '👨‍⚖️',
    '👩‍⚖️',
    '🧑‍🌾',
    '👨‍🌾',
    '👩‍🌾',
    '🧑‍🍳',
    '👨‍🍳',
    '👩‍🍳',
    '🧑‍🔧',
    '👨‍🔧',
    '👩‍🔧',
    '🧑‍🏭',
    '👨‍🏭',
    '👩‍🏭',
    '🧑‍💼',
    '👨‍💼',
    '👩‍💼',
    '🧑‍🔬',
    '👨‍🔬',
    '👩‍🔬',
    '🧑‍💻',
    '👨‍💻',
    '👩‍💻',
    '🧑‍🎤',
    '👨‍🎤',
    '👩‍🎤',
    '🧑‍🎨',
    '👨‍🎨',
    '👩‍🎨',
    '🧑‍✈️',
    '👨‍✈️',
    '👩‍✈️',
    '🧑‍🚀',
    '👨‍🚀',
    '👩‍🚀',
    '🧑‍🚒',
    '👨‍🚒',
    '👩‍🚒',
    '👮',
    '👮‍♂️',
    '👮‍♀️',
    '🕵',
    '🕵️‍♂️',
    '🕵️‍♀️',
    '💂',
    '💂‍♂️',
    '💂‍♀️',
    '🥷',
    '👷',
    '👷‍♂️',
    '👷‍♀️',
    '🫅',
    '🤴',
    '👸',
    '👳',
    '👳‍♂️',
    '👳‍♀️',
    '👲',
    '🧕',
    '🤵',
    '🤵‍♂️',
    '🤵‍♀️',
    '👰',
    '👰‍♂️',
    '👰‍♀️',
    '🤰',
    '🫃',
    '🫄',
    '🤱',
    '👩‍🍼',
    '👨‍🍼',
    '🧑‍🍼',
    '👼',
    '🎅',
    '🤶',
    '🧑‍🎄',
    '🦸',
    '🦸‍♂️',
    '🦸‍♀️',
    '🦹',
    '🦹‍♂️',
    '🦹‍♀️',
    '🧙',
    '🧙‍♂️',
    '🧙‍♀️',
    '🧚',
    '🧚‍♂️',
    '🧚‍♀️',
    '🧛',
    '🧛‍♂️',
    '🧛‍♀️',
    '🧜',
    '🧜‍♂️',
    '🧜‍♀️',
    '🧝',
    '🧝‍♂️',
    '🧝‍♀️',
    '🧞',
    '🧞‍♂️',
    '🧞‍♀️',
    '🧟',
    '🧟‍♂️',
    '🧟‍♀️',
    '🧌',
    '💆',
    '💆‍♂️',
    '💆‍♀️',
    '💇',
    '💇‍♂️',
    '💇‍♀️',
    '🚶',
    '🚶‍♂️',
    '🚶‍♀️',
    '🧍',
    '🧍‍♂️',
    '🧍‍♀️',
    '🧎',
    '🧎‍♂️',
    '🧎‍♀️',
    '🧑‍🦯',
    '👨‍🦯',
    '👩‍🦯',
    '🧑‍🦼',
    '👨‍🦼',
    '👩‍🦼',
    '🧑‍🦽',
    '👨‍🦽',
    '👩‍🦽',
    '🏃',
    '🏃‍♂️',
    '🏃‍♀️',
    '💃',
    '🕺',
    '🕴',
    '👯',
    '👯‍♂️',
    '👯‍♀️',
    '🧖',
    '🧖‍♂️',
    '🧖‍♀️',
    '🧗',
    '🧗‍♂️',
    '🧗‍♀️',
    '🤺',
    '🏇',
    '⛷',
    '🏂',
    '🏌',
    '🏌️‍♂️',
    '🏌️‍♀️',
    '🏄',
    '🏄‍♂️',
    '🏄‍♀️',
    '🚣',
    '🚣‍♂️',
    '🚣‍♀️',
    '🏊',
    '🏊‍♂️',
    '🏊‍♀️',
    '⛹',
    '⛹️‍♂️',
    '⛹️‍♀️',
    '🏋',
    '🏋️‍♂️',
    '🏋️‍♀️',
    '🚴',
    '🚴‍♂️',
    '🚴‍♀️',
    '🚵',
    '🚵‍♂️',
    '🚵‍♀️',
    '🤸',
    '🤸‍♂️',
    '🤸‍♀️',
    '🤼',
    '🤼‍♂️',
    '🤼‍♀️',
    '🤽',
    '🤽‍♂️',
    '🤽‍♀️',
    '🤾',
    '🤾‍♂️',
    '🤾‍♀️',
    '🤹',
    '🤹‍♂️',
    '🤹‍♀️',
    '🧘',
    '🧘‍♂️',
    '🧘‍♀️',
    '🛀',
    '🛌',
    '🧑‍🤝‍🧑',
    '👭',
    '👫',
    '👬',
    '💏',
    '👩‍❤️‍💋‍👨',
    '👨‍❤️‍💋‍👨',
    '👩‍❤️‍💋‍👩',
    '💑',
    '👩‍❤️‍👨',
    '👨‍❤️‍👨',
    '👩‍❤️‍👩',
    '👪',
    '👨‍👩‍👦',
    '👨‍👩‍👧',
    '👨‍👩‍👧‍👦',
    '👨‍👩‍👦‍👦',
    '👨‍👩‍👧‍👧',
    '👨‍👨‍👦',
    '👨‍👨‍👧',
    '👨‍👨‍👧‍👦',
    '👨‍👨‍👦‍👦',
    '👨‍👨‍👧‍👧',
    '👩‍👩‍👦',
    '👩‍👩‍👧',
    '👩‍👩‍👧‍👦',
    '👩‍👩‍👦‍👦',
    '👩‍👩‍👧‍👧',
    '👨‍👦',
    '👨‍👦‍👦',
    '👨‍👧',
    '👨‍👧‍👦',
    '👨‍👧‍👧',
    '👩‍👦',
    '👩‍👦‍👦',
    '👩‍👧',
    '👩‍👧‍👦',
    '👩‍👧‍👧',
    '🗣',
    '👤',
    '👥',
    '👣',
    '🦰',
    '🦱',
    '🦳',
    '🦲',
    '🐵',
    '🐒',
    '🦍',
    '🦧',
    '🐶',
    '🐕',
    '🦮',
    '🐕‍🦺',
    '🐩',
    '🐺',
    '🦊',
    '🦝',
    '🐱',
    '🐈',
    '🐈‍⬛',
    '🦁',
    '🐯',
    '🐅',
    '🐆',
    '🐴',
    '🐎',
    '🦄',
    '🦓',
    '🦌',
    '🦬',
    '🐮',
    '🐂',
    '🐃',
    '🐄',
    '🐷',
    '🐖',
    '🐗',
    '🐽',
    '🐏',
    '🐑',
    '🐐',
    '🐪',
    '🐫',
    '🦙',
    '🦒',
    '🐘',
    '🦏',
    '🦛',
    '🐭',
    '🐁',
    '🐀',
    '🐹',
    '🐰',
    '🐇',
    '🐿',
    '🦫',
    '🦔',
    '🦇',
    '🐻',
    '🐻‍❄️',
    '🐨',
    '🐼',
    '🦥',
    '🦦',
    '🦨',
    '🦘',
    '🦡',
    '🐾',
    '🦃',
    '🐔',
    '🐓',
    '🐣',
    '🐤',
    '🐥',
    '🐦',
    '🐧',
    '🕊',
    '🦅',
    '🦆',
    '🦢',
    '🦉',
    '🦩',
    '🦚',
    '🦜',
    '🐸',
    '🐊',
    '🐢',
    '🦎',
    '🐍',
    '🐲',
    '🐉',
    '🦕',
    '🦖',
    '🐳',
    '🐋',
    '🐬',
    '🐟',
    '🐠',
    '🐡',
    '🦈',
    '🐙',
    '🐚',
    '🪸',
    '🐌',
    '🦋',
    '🐛',
    '🐜',
    '🐝',
    '🐞',
    '🦗',
    '🕷',
    '🕸',
    '🦂',
    '🦟',
    '🦠',
    '💐',
    '🌸',
    '💮',
    '🏵',
    '🌹',
    '🥀',
    '🌺',
    '🌻',
    '🌼',
    '🌷',
    '🌱',
    '🌲',
    '🌳',
    '🌴',
    '🌵',
    '🌾',
    '🌿',
    '☘',
    '🍀',
    '🍁',
    '🍂',
    '🍃',
    '🍇',
    '🍈',
    '🍉',
    '🍊',
    '🍋',
    '🍌',
    '🍍',
    '🥭',
    '🍎',
    '🍏',
    '🍐',
    '🍑',
    '🍒',
    '🍓',
    '🥝',
    '🍅',
    '🥥',
    '🥑',
    '🍆',
    '🥔',
    '🥕',
    '🌽',
    '🌶',
    '🥒',
    '🥬',
    '🥦',
    '🧄',
    '🧅',
    '🍄',
    '🥜',
    '🌰',
    '🍞',
    '🥐',
    '🥖',
    '🥨',
    '🥯',
    '🥞',
    '🧇',
    '🧀',
    '🍖',
    '🍗',
    '🥩',
    '🥓',
    '🍔',
    '🍟',
    '🍕',
    '🌭',
    '🥪',
    '🌮',
    '🌯',
    '🥙',
    '🧆',
    '🥚',
    '🍳',
    '🥘',
    '🍲',
    '🥣',
    '🥗',
    '🍿',
    '🧈',
    '🧂',
    '🥫',
    '🍱',
    '🍘',
    '🍙',
    '🍚',
    '🍛',
    '🍜',
    '🍝',
    '🍠',
    '🍢',
    '🍣',
    '🍤',
    '🍥',
    '🥮',
    '🍡',
    '🥟',
    '🥠',
    '🥡',
    '🦀',
    '🦞',
    '🦐',
    '🦑',
    '🦪',
    '🍦',
    '🍧',
    '🍨',
    '🍩',
    '🍪',
    '🎂',
    '🍰',
    '🧁',
    '🥧',
    '🍫',
    '🍬',
    '🍭',
    '🍮',
    '🍯',
    '🍼',
    '🥛',
    '☕',
    '🍵',
    '🍶',
    '🍾',
    '🍷',
    '🍸',
    '🍹',
    '🍺',
    '🍻',
    '🥂',
    '🥃',
    '🥤',
    '🧃',
    '🧉',
    '🧊',
    '🥢',
    '🍽',
    '🍴',
    '🥄',
    '🔪',
    '🏺',
    '🌍',
    '🌎',
    '🌏',
    '🌐',
    '🗺',
    '🗾',
    '🧭',
    '🏔',
    '⛰',
    '🌋',
    '🗻',
    '🏕',
    '🏖',
    '🏜',
    '🏝',
    '🏞',
    '🏟',
    '🏛',
    '🏗',
    '🧱',
    '🏘',
    '🏚',
    '🏠',
    '🏡',
    '🏢',
    '🏣',
    '🏤',
    '🏥',
    '🏦',
    '🏨',
    '🏩',
    '🏪',
    '🏫',
    '🏬',
    '🏭',
    '🏯',
    '🏰',
    '💒',
    '🗼',
    '🗽',
    '⛪',
    '🕌',
    '🛕',
    '🕍',
    '⛩',
    '🕋',
    '⛲',
    '⛺',
    '🌁',
    '🌃',
    '🏙',
    '🌄',
    '🌅',
    '🌆',
    '🌇',
    '🌉',
    '♨',
    '🎠',
    '🎡',
    '🎢',
    '💈',
    '🎪',
    '🚂',
    '🚃',
    '🚄',
    '🚅',
    '🚆',
    '🚇',
    '🚈',
    '🚉',
    '🚊',
    '🚝',
    '🚞',
    '🚋',
    '🚌',
    '🚍',
    '🚎',
    '🚐',
    '🚑',
    '🚒',
    '🚓',
    '🚔',
    '🚕',
    '🚖',
    '🚗',
    '🚘',
    '🚙',
    '🚚',
    '🚛',
    '🚜',
    '🏎',
    '🏍',
    '🛵',
    '🦽',
    '🦼',
    '🛺',
    '🚲',
    '🛴',
    '🛹',
    '🚏',
    '🛣',
    '🛤',
    '🛢',
    '⛽',
    '🚨',
    '🚥',
    '🚦',
    '🛑',
    '🚧',
    '⚓',
    '⛵',
    '🛶',
    '🚤',
    '🛳',
    '⛴',
    '🛥',
    '🚢',
    '✈',
    '🛩',
    '🛫',
    '🛬',
    '🪂',
    '💺',
    '🚁',
    '🚟',
    '🚠',
    '🚡',
    '🛰',
    '🚀',
    '🛸',
    '🛎',
    '🧳',
    '⌛',
    '⏳',
    '⌚',
    '⏰',
    '🌑',
    '🌒',
    '🌓',
    '🌔',
    '🌕',
    '🌖',
    '🌗',
    '🌘',
    '🌙',
    '🌚',
    '🌛',
    '🌜',
    '🌡',
    '☀',
    '🌝',
    '🌞',
    '🪐',
    '⭐',
    '🌟',
    '🌠',
    '🌌',
    '☁',
    '⛅',
    '⛈',
    '🌤',
    '🌥',
    '🌦',
    '🌧',
    '🌨',
    '🌩',
    '🌪',
    '🌫',
    '🌬',
    '🌀',
    '🌈',
    '🌂',
    '☂',
    '☔',
    '⛱',
    '⚡',
    '❄',
    '☃',
    '⛄',
    '🔥',
    '🌊',
    '🎃',
    '🎄',
    '🎆',
    '🎇',
    '🧨',
    '✨',
    '🎈',
    '🎉',
    '🎊',
    '🎋',
    '🎍',
    '🎎',
    '🎏',
    '🎐',
    '🎑',
    '🧧',
    '🎀',
    '🎁',
    '🎗',
    '🎟',
    '🎫',
    '🎖',
    '🏆',
    '🏅',
    '🥇',
    '🥈',
    '🥉',
    '⚽',
    '⚾',
    '🥎',
    '🏀',
    '🏐',
    '🏈',
    '🏉',
    '🎾',
    '🥏',
    '🎳',
    '🏏',
    '🏑',
    '🏒',
    '🥍',
    '🏓',
    '🏸',
    '🥊',
    '🥋',
    '🥅',
    '⛳',
    '⛸',
    '🎣',
    '🤿',
    '🎽',
    '🎿',
    '🛷',
    '🥌',
    '🎯',
    '🪀',
    '🪁',
    '🎱',
    '🔮',
    '🧿',
    '🎮',
    '🕹',
    '🎰',
    '🎲',
    '🧩',
    '🧸',
    '♠',
    '♥',
    '♦',
    '♣',
    '♟',
    '🃏',
    '🀄',
    '🎴',
    '🎭',
    '🖼',
    '🎨',
    '🧵',
    '🧶',
    '👓',
    '🕶',
    '🥽',
    '🥼',
    '🦺',
    '👔',
    '👕',
    '👖',
    '🧣',
    '🧤',
    '🧥',
    '🧦',
    '👗',
    '👘',
    '🥻',
    '🩱',
    '🩲',
    '🩳',
    '👙',
    '👚',
    '👛',
    '👜',
    '👝',
    '🛍',
    '🎒',
    '👞',
    '👟',
    '🥾',
    '🥿',
    '👠',
    '👡',
    '🩰',
    '👢',
    '👑',
    '👒',
    '🎩',
    '🎓',
    '🧢',
    '⛑',
    '📿',
    '💄',
    '💍',
    '💎',
    '🔇',
    '🔈',
    '🔉',
    '🔊',
    '📢',
    '📣',
    '📯',
    '🔔',
    '🔕',
    '🎼',
    '🎵',
    '🎶',
    '🎙',
    '🎚',
    '🎛',
    '🎤',
    '🎧',
    '📻',
    '🎷',
    '🪗',
    '🎸',
    '🎹',
    '🎺',
    '🎻',
    '🪕',
    '🥁',
    '🪘',
    '📱',
    '📲',
    '☎',
    '📞',
    '📟',
    '📠',
    '🔋',
    '🔌',
    '💻',
    '🖥',
    '🖨',
    '⌨',
    '🖱',
    '🖲',
    '💽',
    '💾',
    '💿',
    '📀',
    '🧮',
    '🎥',
    '🎞',
    '📽',
    '🎬',
    '📺',
    '📷'
  ]

  return emojis[~~(Math.random() * emojis.length)]
}
